@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  /* h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  } */
}

@layer components {
  .btn {
    @apply text-neutral-400
            p-2 py-0 m-1 cursor-pointer
            border border-neutral-400 rounded
            hover:bg-neutral-400 hover:text-white;
  }
}

@layer utilities {
  /* .filter-none {
    filter: none;
  }
  .filter-grayscale {
    filter: grayscale(100%);
  } */
}